<template>
  <el-dialog
    width="700px"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    title="选择图片"
  >
    <div class="cover-cropper">
      <div class="cropper-wrap">
        <vue-cropper
          ref="cropper1"
          class="cropper"
          :class="{ active: cropType === 1 }"
          :img="cropperOption1.img"
          :outputType="cropperOption.outputType"
          :autoCrop="cropperOption.autoCrop"
          :fixed="cropperOption.fixed"
          :fixedNumber="[8, 4]"
          :infoTrue="cropperOption.infoTrue"
          :centerBox="cropperOption.centerBox"
          :enlarge="cropperOption.enlarge"
          @realTime="handleCropperRealTime1"
        ></vue-cropper>
        <vue-cropper
          ref="cropper2"
          class="cropper"
          :class="{ active: cropType === 2 }"
          :img="cropperOption2.img"
          :outputType="cropperOption.outputType"
          :autoCrop="cropperOption.autoCrop"
          :fixed="cropperOption.fixed"
          :fixedNumber="[4, 4]"
          :infoTrue="cropperOption.infoTrue"
          :centerBox="cropperOption.centerBox"
          :enlarge="cropperOption.enlarge"
          @realTime="handleCropperRealTime2"
        ></vue-cropper>
      </div>
      <div class="actions">
        <div>
          <div
            class="type-action type1"
            :class="{ active: cropType === 1 }"
            @click="handleCropTypeClick(1)"
          >
            <div class="preview">
              <div
                :style="{ transform: `scale(${100 / previewData1.w})` }"
                v-html="previewBoxHTML1"
              ></div>
            </div>
            <div class="txt">
              <p>图文封面（2:1）</p>
              <span>适用于用户服务窗消息卡片列表等场景</span>
            </div>
          </div>
          <div
            class="type-action type2"
            :class="{ active: cropType === 2 }"
            @click="handleCropTypeClick(2)"
          >
            <div class="preview">
              <div
                :style="{ transform: `scale(${50 / previewData2.w})` }"
                v-html="previewBoxHTML2"
              ></div>
            </div>
            <div class="txt">
              <p>图文封面（1:1）</p>
              <span>适用于用户转发的链接卡片等场景</span>
            </div>
          </div>
        </div>
        <el-upload
          multiple
          action=""
          :auto-upload="false"
          :on-change="handleFileChange"
          accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
        >
          <el-button class="upload-btn" size="small" type="info">
            <i class="el-icon-upload"></i>
            重新上传图片
          </el-button>
          <div slot="file"></div>
        </el-upload>
        <div class="btn-group">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="confirmCrop" :loading="uploading"
            >确定</el-button
          >
        </div>
      </div>
    </div>
    <f-upload v-show="false" ref="uploader"></f-upload>
  </el-dialog>
</template>

<script>
import { VueCropper } from "vue-cropper";
import FUpload from "@/components/upload";
export default {
  components: {
    FUpload
  },
  props: {
    coverUrl: String
  },
  data() {
    return {
      cropType: 1,
      cropperOption1: {
        img: "" // 裁剪图片的地址
      },
      cropperOption2: {
        img: "" // 裁剪图片的地址
      },
      cropperOption: {
        img: "", // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        autoCrop: true, // 是否默认生成截图框
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [8, 4], // 截图框的宽高比例
        centerBox: true, //截图框是否被限制在图片里面
        enlarge: 1, //图片根据截图框输出比例倍数
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previewBoxHTML1: "", // 预览图片盒子节点元素
      previewData1: {}, // 预览图片数据
      previewBoxHTML2: "", // 预览图片盒子节点元素
      previewData2: {}, // 预览图片数据
      uploading: false //图片上传中
    };
  },
  watch: {
    coverUrl(val) {
      this.cropperOption1.img = val;
      this.cropperOption2.img = val;
    }
  },
  methods: {
    // 关闭对话框
    close() {
      this.$emit("update:visible", false);
    },
    // 确定裁剪
    async confirmCrop() {
      this.uploading = true;
      const getUrl1 = new Promise(resolve => {
        this.$refs.cropper1.getCropBlob(async file => {
          // 上传裁剪后的图片
          const url = await this.$refs.uploader.upload(file);
          resolve(url);
        });
      });
      const getUrl2 = new Promise(resolve => {
        this.$refs.cropper2.getCropBlob(async file => {
          // 上传裁剪后的图片
          const url = await this.$refs.uploader.upload(file);
          resolve(url);
        });
      });
      const [url1, url2] = await Promise.all([getUrl1, getUrl2]);
      this.uploading = false;
      this.$emit("success", {
        cover: url1,
        shareCover: url2
      });
      this.close();
    },
    // 选择图片
    handleFileChange(file) {
      // file转换为临时路径
      const url = URL.createObjectURL(file.raw);
      this.cropperOption1.img = url;
      this.cropperOption2.img = url;
    },
    // 切换裁剪类型
    handleCropTypeClick(type) {
      this.cropType = type;
    },
    // 实时预览事件
    handleCropperRealTime1(data) {
      this.previewBoxHTML1 = data.html;
      this.previewData1 = data;
    },
    // 实时预览事件
    handleCropperRealTime2(data) {
      this.previewBoxHTML2 = data.html;
      this.previewData2 = data;
    }
  }
};
</script>

<style scoped lang="scss">
.cover-cropper {
  display: flex;
  .cropper-wrap {
    width: 400px;
    height: 300px;
    position: relative;
    .cropper {
      position: absolute;
      left: 0;
      top: 0;
      &.active {
        z-index: 2;
      }
    }
  }
  .actions {
    padding-left: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ::v-deep {
      .el-upload {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .btn-group {
      text-align: right;
    }
    .upload-btn {
      width: 100%;
      color: #666;
    }
    .type-action {
      padding: 10px;
      display: flex;
      &:hover,
      &.active {
        cursor: pointer;
        background: #ecf5ff;
      }
      .txt {
        padding-left: 10px;
        span {
          font-size: 12px;
          color: #999;
        }
      }
      .preview {
        width: 100px;
        min-width: 100px;
        height: 50px;
        overflow: hidden;
        background: #eee;
        & > div {
          transform-origin: 0 0;
        }
      }
      &.type2 {
        .preview {
          width: 50px;
          min-width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>
