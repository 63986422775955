var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "700px",
      "close-on-click-modal": false,
      "title": "选择图片"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "cover-cropper"
  }, [_c('div', {
    staticClass: "cropper-wrap"
  }, [_c('vue-cropper', {
    ref: "cropper1",
    staticClass: "cropper",
    class: {
      active: _vm.cropType === 1
    },
    attrs: {
      "img": _vm.cropperOption1.img,
      "outputType": _vm.cropperOption.outputType,
      "autoCrop": _vm.cropperOption.autoCrop,
      "fixed": _vm.cropperOption.fixed,
      "fixedNumber": [8, 4],
      "infoTrue": _vm.cropperOption.infoTrue,
      "centerBox": _vm.cropperOption.centerBox,
      "enlarge": _vm.cropperOption.enlarge
    },
    on: {
      "realTime": _vm.handleCropperRealTime1
    }
  }), _c('vue-cropper', {
    ref: "cropper2",
    staticClass: "cropper",
    class: {
      active: _vm.cropType === 2
    },
    attrs: {
      "img": _vm.cropperOption2.img,
      "outputType": _vm.cropperOption.outputType,
      "autoCrop": _vm.cropperOption.autoCrop,
      "fixed": _vm.cropperOption.fixed,
      "fixedNumber": [4, 4],
      "infoTrue": _vm.cropperOption.infoTrue,
      "centerBox": _vm.cropperOption.centerBox,
      "enlarge": _vm.cropperOption.enlarge
    },
    on: {
      "realTime": _vm.handleCropperRealTime2
    }
  })], 1), _c('div', {
    staticClass: "actions"
  }, [_c('div', [_c('div', {
    staticClass: "type-action type1",
    class: {
      active: _vm.cropType === 1
    },
    on: {
      "click": function click($event) {
        return _vm.handleCropTypeClick(1);
      }
    }
  }, [_c('div', {
    staticClass: "preview"
  }, [_c('div', {
    style: {
      transform: "scale(".concat(100 / _vm.previewData1.w, ")")
    },
    domProps: {
      "innerHTML": _vm._s(_vm.previewBoxHTML1)
    }
  })]), _c('div', {
    staticClass: "txt"
  }, [_c('p', [_vm._v("图文封面（2:1）")]), _c('span', [_vm._v("适用于用户服务窗消息卡片列表等场景")])])]), _c('div', {
    staticClass: "type-action type2",
    class: {
      active: _vm.cropType === 2
    },
    on: {
      "click": function click($event) {
        return _vm.handleCropTypeClick(2);
      }
    }
  }, [_c('div', {
    staticClass: "preview"
  }, [_c('div', {
    style: {
      transform: "scale(".concat(50 / _vm.previewData2.w, ")")
    },
    domProps: {
      "innerHTML": _vm._s(_vm.previewBoxHTML2)
    }
  })]), _c('div', {
    staticClass: "txt"
  }, [_c('p', [_vm._v("图文封面（1:1）")]), _c('span', [_vm._v("适用于用户转发的链接卡片等场景")])])])]), _c('el-upload', {
    attrs: {
      "multiple": "",
      "action": "",
      "auto-upload": false,
      "on-change": _vm.handleFileChange,
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp"
    }
  }, [_c('el-button', {
    staticClass: "upload-btn",
    attrs: {
      "size": "small",
      "type": "info"
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" 重新上传图片 ")]), _c('div', {
    attrs: {
      "slot": "file"
    },
    slot: "file"
  })], 1), _c('div', {
    staticClass: "btn-group"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.uploading
    },
    on: {
      "click": _vm.confirmCrop
    }
  }, [_vm._v("确定")])], 1)], 1)]), _c('f-upload', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "uploader"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }