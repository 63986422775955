<template>
  <div class="container">
    <div class="editor-head">
      <div class="actions">
        <span @click="showImageExplorer">图片</span>
        <span @click="linkDialogVisible = true">超链接</span>
        <span @click="fileDialogVisible = true">文档</span>
      </div>
    </div>
    <div class="editor-wrap">
      <div class="view-card">
        <el-image :src="formData.cover" fit="cover">
          <div class="img-slot" slot="error">
            <i class="el-icon-picture"></i>
          </div>
        </el-image>
        <p class="title">{{ formData.title || "请输入标题" }}</p>
        <p class="summary">{{ previewSummary || "请输入内容" }}</p>
      </div>
      <div class="editor-container" :class="{ disabledToolbar }">
        <div class="editor-box">
          <el-input
            class="title"
            v-model="formData.title"
            placeholder="请在这里输入标题"
            @focus="disabledToolbar = true"
            @blur="disabledToolbar = false"
          ></el-input>
          <f-editor
            @on-change="handleEditorChange"
            :defaultContent="detail.context"
            ref="editor"
          ></f-editor>
          <div class="form" :class="{ disabled: formData.status === 1 }">
            <div class="form-cell">
              <p class="form-cell-title">封面和摘要</p>
              <div class="cover-wrap">
                <div class="cover">
                  <div
                    class="image"
                    @click="coverDialogVisible = true"
                    v-if="formData.cover"
                  >
                    <el-image :src="formData.cover" fit="cover"></el-image>
                    <div class="mask">
                      <div class="icon">
                        <i class="el-icon-edit-outline"></i>
                      </div>
                    </div>
                  </div>
                  <el-upload
                    v-else
                    multiple
                    action=""
                    accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
                    :auto-upload="false"
                    :on-change="chooseImage"
                  >
                    <div class="upload-trigger">
                      <i class="el-icon-upload"></i>
                      上传图片
                    </div>
                  </el-upload>
                </div>
                <div class="summary">
                  <el-input
                    resize="none"
                    type="textarea"
                    maxlength="100"
                    v-model="formData.summary"
                    placeholder="选填，摘要主要在用户服务窗、转发链接等文章外的场景显示，帮助用户快速理解内容，如不填写则默认抓取正文前50个字"
                  ></el-input>
                  <div class="count">{{ formData.summary.length }} / 100</div>
                </div>
              </div>
            </div>
            <div class="form-cell">
              <public-setting
                ref="setting"
                :detailForm="detail"
              ></public-setting>
            </div>
            <div class="mask"></div>
          </div>
          <c-foot-bar
            :word-count="contextWordCount"
            :form-data="{
              ...formData,
              summary: previewSummary,
              ...($refs.setting?.formData || {})
            }"
            :before-submit="validateForm"
            @reset="resetForm"
          ></c-foot-bar>
        </div>
      </div>
    </div>
    <c-file-dialog
      :visible.sync="fileDialogVisible"
      @select="getFiles"
    ></c-file-dialog>
    <c-link-dialog
      :visible.sync="linkDialogVisible"
      @success="getLink"
    ></c-link-dialog>
    <c-cover-dialog
      :visible.sync="coverDialogVisible"
      :cover-url="coverUrl"
      @success="getCoverImage"
    ></c-cover-dialog>
  </div>
</template>

<script>
import FEditor from "@/components/editor";
import CFileDialog from "./_components/file";
import CCoverDialog from "./_components/cover";
import CLinkDialog from "./_components/link";
import CFootBar from "./_components/footBar";
import PublicSetting from "./_components/publicSetting";
import { getMessageDetail, getMessageRecordDetail } from "@/api/message";
const formData = {
  title: "", // 标题
  context: "", // 正文
  summary: "", // 摘要
  cover: "", // 图文封面
  shareCover: "", // 分享图文封面
  dataType: 2, //1: 群发， 2：发布} ,
  regularType: null, //是否定时群发:{1: 定时群发, 2: 立即/分组群发} ,
  regularDate: "", //定时群发时间
  regularMember: "", // 群发成员: [[1, 2, 3], [1, 2, 3]]
  regularNumber: "", //群发次数 ,
  publishType: 1, //发表类型：1图文，3文字，4图片
  status: null //{1: 正常, 2: 草稿} ,
};
export default {
  components: {
    FEditor,
    CFileDialog,
    CCoverDialog,
    CLinkDialog,
    PublicSetting,
    CFootBar
  },
  data() {
    return {
      disabledToolbar: false, //禁用工具栏
      formData: this.$deepClone(formData),
      coverUrl: "", // 封面路径
      fileDialogVisible: false, //显示添加附件
      coverDialogVisible: false, //显示封面裁剪
      linkDialogVisible: false, //显示添加链接
      detail: {}
    };
  },
  computed: {
    previewSummary() {
      const { context, summary } = this.formData;
      if (summary) {
        return summary;
      }
      if (context) {
        return document
          .querySelector("#f-editor .ql-editor")
          ?.innerText.slice(0, 50)
          .replace(/\n+/g, " ");
      }
      return "";
    },
    contextWordCount() {
      if (this.formData.context) {
        return document
          .querySelector("#f-editor .ql-editor")
          ?.innerText.replace(/\n+/g, "").length;
      }
      return 0;
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    async getDetail() {
      const { id, status } = this.$route.query;
      const fn = status == 1 ? getMessageRecordDetail : getMessageDetail;
      const res = await fn(id);
      if (res) {
        this.formData = res;
        this.detail = res;
      }
    },
    // 点击上传图片 触发editor内的f-upload组件的上传方法
    showImageExplorer() {
      document.querySelector(".pic-uploader input").click();
    },
    handleEditorChange(data) {
      //编辑器编辑
      this.formData.context = data.html;
    },
    // 上传成功后获取封面图片
    getCoverImage(res) {
      this.formData.cover = res.cover;
      this.formData.shareCover = res.shareCover;
    },
    // 获取插入的超链接对象
    getLink(res) {
      this.$refs.editor.insertLinkBlot(res);
    },
    // 获取需要插入的文件信息
    getFiles(res) {
      this.$refs.editor.insertFileBlot(res);
    },
    // 选择图片
    chooseImage(file) {
      this.coverUrl = URL.createObjectURL(file.raw);
      this.coverDialogVisible = true;
    },
    // 重置表单
    resetForm() {
      this.formData = this.$deepClone(formData);
      this.$refs.editor.clear();
    },
    // 验证表单是否填写完整
    validateForm() {
      const { title, context, cover } = this.formData;
      const { formData, regularMass, dayError } = this.$refs.setting;
      let msg;
      if (!title.trim()) {
        msg = "请输入标题";
      } else if (!context.trim()) {
        msg = "请输入内容";
      } else if (!cover) {
        msg = "请上传消息封面图";
      } else if (formData.dataType == 1 && regularMass && dayError) {
        msg = "无剩余次数";
      }
      if (msg) {
        this.$showError(msg);
        return false;
      }
      return true;
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  margin: -20px;
  height: calc(100% + 40px);
  background: #f8f8f8;
  overflow: hidden;
  .editor-head {
    height: 50px;
    position: relative;
    background: #f5f5f5;
    @include flex-xyc;
    .actions {
      display: flex;
      position: fixed;
      top: 10px;
      border-radius: 5px;
      overflow: hidden;
      span {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        background: #eee;
        cursor: pointer;
      }
    }
  }
  .editor-wrap {
    padding-left: 20px;
    position: relative;
    @include flex-b;
    .view-card {
      margin-top: 20px;
      padding-bottom: 10px;
      width: 320px;
      height: fit-content;
      margin-right: 50px;
      border-radius: 10px;
      overflow: hidden;
      background: #fff;
      ::v-deep {
        .img-slot {
          color: #999;
          font-size: 30px;
          background: #eee;
          @include flex-xyc;
        }
        .el-image,
        .img-slot {
          width: 320px;
          height: 160px;
          overflow: hidden;
        }
      }
      .title {
        padding: 5px 10px 0 10px;
        font-size: 16px;
        line-height: 24px;
        min-height: 24px;
        font-weight: bold;
        box-sizing: content-box;
      }
      .summary {
        padding: 5px 10px 0 10px;
        color: #666;
        line-height: 20px;
        min-height: 20px;
        box-sizing: content-box;
      }
    }
    .editor-container {
      flex: 1;
      display: flex;
      justify-content: center;
      overflow-y: auto;
      height: calc(100vh - 120px);
      &.disabledToolbar {
        ::v-deep {
          .ql-toolbar {
            pointer-events: none;
            .ql-formats {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .editor-box {
      margin-top: 20px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      height: fit-content;
      margin-bottom: 50px;
      width: 800px;
    }
    .cover-wrap {
      display: flex;
      margin-top: 20px;

      .cover {
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        ::v-deep {
          .el-upload-list {
            display: none;
          }
        }
      }
      .summary {
        height: 125px;
        flex: 1;
        margin-left: 20px;
        border: 1px solid #eee;
        border-radius: 4px;
        ::v-deep {
          textarea {
            border: none;
            height: 100px;
          }
        }
        .count {
          padding-right: 10px;
          text-align: right;
          color: #999;
        }
      }
      .upload-trigger,
      .image {
        width: 250px;
        height: 125px;
        color: #999;
        cursor: pointer;
        border-radius: 10px;
        border: 1px dashed #eee;
        border-radius: 4px;
        @include flex-xyc;
      }
      .upload-trigger {
        i {
          font-size: 24px;
          padding-right: 10px;
        }
      }
      .image {
        ::v-deep {
          .el-image {
            height: 250px;
            height: 125px;
          }
        }
      }
      .mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        opacity: 1;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        @include flex-xyc;
        ::v-deep {
          .el-upload {
            width: 250px;
          }
        }
        .icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #fff;
          @include flex-xyc;
          i {
            font-size: 20px;
            color: #666;
          }
        }
      }
    }
    .title {
      background: #fff;
      ::v-deep {
        .el-input__inner {
          font-size: 24px;
          height: 90px;
          padding: 20px 0 10px 0;
          width: calc(100% - 60px);
          border-radius: 0;
          margin: 0 30px;
          color: #333;
          border: none;
          border-bottom: 1px solid #eee;
        }
      }
    }
    .form {
      margin: 0 50px;
      border-top: 1px solid #eee;
      position: relative;
      &.disabled {
        opacity: 0.5;
        position: relative;
        .mask {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 4;
        }
      }
      .form-cell-title {
        font-size: 17px;
      }
      .form-cell {
        margin-top: 30px;
      }
    }
    ::v-deep {
      .ql-toolbar {
        height: 50px;
        position: absolute;
        left: 0;
        right: 0;
        top: -50px;
        z-index: 1;
        display: flex;
        justify-content: center;
      }
      .ql-snow {
        border: none;
      }
      .ql-editor {
        width: 800px;
        padding: 15px 30px;
        background: #fff;
        border-radius: 0 0 10px 10px;
        &.ql-blank {
          &::before {
            left: 30px;
            right: 30px;
          }
        }
      }
    }
  }
}
</style>
