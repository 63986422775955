var render = function render(){
  var _vm$$refs$setting;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "editor-head"
  }, [_c('div', {
    staticClass: "actions"
  }, [_c('span', {
    on: {
      "click": _vm.showImageExplorer
    }
  }, [_vm._v("图片")]), _c('span', {
    on: {
      "click": function click($event) {
        _vm.linkDialogVisible = true;
      }
    }
  }, [_vm._v("超链接")]), _c('span', {
    on: {
      "click": function click($event) {
        _vm.fileDialogVisible = true;
      }
    }
  }, [_vm._v("文档")])])]), _c('div', {
    staticClass: "editor-wrap"
  }, [_c('div', {
    staticClass: "view-card"
  }, [_c('el-image', {
    attrs: {
      "src": _vm.formData.cover,
      "fit": "cover"
    }
  }, [_c('div', {
    staticClass: "img-slot",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [_c('i', {
    staticClass: "el-icon-picture"
  })])]), _c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.formData.title || "请输入标题"))]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(_vm._s(_vm.previewSummary || "请输入内容"))])], 1), _c('div', {
    staticClass: "editor-container",
    class: {
      disabledToolbar: _vm.disabledToolbar
    }
  }, [_c('div', {
    staticClass: "editor-box"
  }, [_c('el-input', {
    staticClass: "title",
    attrs: {
      "placeholder": "请在这里输入标题"
    },
    on: {
      "focus": function focus($event) {
        _vm.disabledToolbar = true;
      },
      "blur": function blur($event) {
        _vm.disabledToolbar = false;
      }
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  }), _c('f-editor', {
    ref: "editor",
    attrs: {
      "defaultContent": _vm.detail.context
    },
    on: {
      "on-change": _vm.handleEditorChange
    }
  }), _c('div', {
    staticClass: "form",
    class: {
      disabled: _vm.formData.status === 1
    }
  }, [_c('div', {
    staticClass: "form-cell"
  }, [_c('p', {
    staticClass: "form-cell-title"
  }, [_vm._v("封面和摘要")]), _c('div', {
    staticClass: "cover-wrap"
  }, [_c('div', {
    staticClass: "cover"
  }, [_vm.formData.cover ? _c('div', {
    staticClass: "image",
    on: {
      "click": function click($event) {
        _vm.coverDialogVisible = true;
      }
    }
  }, [_c('el-image', {
    attrs: {
      "src": _vm.formData.cover,
      "fit": "cover"
    }
  }), _vm._m(0)], 1) : _c('el-upload', {
    attrs: {
      "multiple": "",
      "action": "",
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "auto-upload": false,
      "on-change": _vm.chooseImage
    }
  }, [_c('div', {
    staticClass: "upload-trigger"
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" 上传图片 ")])])], 1), _c('div', {
    staticClass: "summary"
  }, [_c('el-input', {
    attrs: {
      "resize": "none",
      "type": "textarea",
      "maxlength": "100",
      "placeholder": "选填，摘要主要在用户服务窗、转发链接等文章外的场景显示，帮助用户快速理解内容，如不填写则默认抓取正文前50个字"
    },
    model: {
      value: _vm.formData.summary,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "summary", $$v);
      },
      expression: "formData.summary"
    }
  }), _c('div', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.formData.summary.length) + " / 100")])], 1)])]), _c('div', {
    staticClass: "form-cell"
  }, [_c('public-setting', {
    ref: "setting",
    attrs: {
      "detailForm": _vm.detail
    }
  })], 1), _c('div', {
    staticClass: "mask"
  })]), _c('c-foot-bar', {
    attrs: {
      "word-count": _vm.contextWordCount,
      "form-data": Object.assign({}, _vm.formData, {
        summary: _vm.previewSummary
      }, ((_vm$$refs$setting = _vm.$refs.setting) === null || _vm$$refs$setting === void 0 ? void 0 : _vm$$refs$setting.formData) || {}),
      "before-submit": _vm.validateForm
    },
    on: {
      "reset": _vm.resetForm
    }
  })], 1)])]), _c('c-file-dialog', {
    attrs: {
      "visible": _vm.fileDialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.fileDialogVisible = $event;
      },
      "select": _vm.getFiles
    }
  }), _c('c-link-dialog', {
    attrs: {
      "visible": _vm.linkDialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.linkDialogVisible = $event;
      },
      "success": _vm.getLink
    }
  }), _c('c-cover-dialog', {
    attrs: {
      "visible": _vm.coverDialogVisible,
      "cover-url": _vm.coverUrl
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.coverDialogVisible = $event;
      },
      "success": _vm.getCoverImage
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mask"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "el-icon-edit-outline"
  })])]);

}]

export { render, staticRenderFns }