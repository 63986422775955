<template>
  <el-dialog
    :close-on-click-modal="false"
    v-bind="$attrs"
    v-on="$listeners"
    title="添加超链接"
    width="700px"
    :before-close="close"
  >
    <el-form
      ref="form"
      label-position="left"
      label-width="100px"
      :model="formData"
    >
      <el-form-item
        label="链接标题"
        prop="title"
        :rules="[$formRules.required()]"
      >
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item
        label="输入链接"
        prop="link"
        :rules="[$formRules.required(), linkRule]"
      >
        <el-input
          v-model="formData.link"
          placeholder="请输入http://或https://开头的可用域名链接"
        ></el-input>
        <p class="tips">可用域名 fangyibao.cn、fangmao.com</p>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        title: "",
        link: ""
      },
      linkRule: {
        message: "请检查链接是否正确",
        pattern: /^(https|http):\/\/(\w+\.)?(fangyibao\.cn|fangmao\.com)/,
        trigger: "blur"
      }
    };
  },
  methods: {
    close() {
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    confirm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$emit("success", this.formData);
          this.close();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.tips {
  color: #999;
  margin-top: 10px;
}
</style>
