var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-editor",
    attrs: {
      "id": "f-editor"
    }
  }, [_c('quill-editor', {
    ref: "QuillEditor",
    staticClass: "editor",
    attrs: {
      "options": _vm.editorOption
    },
    on: {
      "change": function change($event) {
        return _vm.onEditorChange($event);
      }
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }), _c('f-upload', {
    ref: "picUploader",
    staticClass: "pic-uploader",
    attrs: {
      "type": "2"
    },
    on: {
      "on-success": _vm.imgUploadSuccess
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }