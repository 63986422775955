<template>
  <div>
    <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      v-on="$listeners"
      width="700px"
      title="选择文档"
      @open="getFileList"
    >
      <div class="head">
        <p>文档库</p>
        <el-button type="info" size="small" @click="handleEditClick(null)"
          >本地上传</el-button
        >
      </div>
      <div class="file-list">
        <div class="item" v-for="item of fileList" :key="item.id">
          <div class="img-box">
            <img src="@/assets/word.png" v-if="item.docType == 'word'" />
            <img src="@/assets/excel.png" v-if="item.docType == 'excel'" />
            <img src="@/assets/pdf.png" v-if="item.docType == 'pdf'" />
            <img :src="item.url" v-if="item.docType == 'image'" />
          </div>
          <div class="info">
            <div class="title">{{ item.docName }}</div>
            <p>
              大小 {{ $format.byte(item.size) }}
              <span> {{ item.createTime }}</span>
            </p>
          </div>
          <div class="actions">
            <i class="el-icon-edit-outline" @click="handleEditClick(item)"></i>
            <el-popover
              placement="bottom-end"
              width="290"
              trigger="click"
              v-model="item.showPopover"
            >
              <div class="hand-popover">
                删除该文档后将无法恢复，所有引用该文档的网页中对应的文档都会被删除。
                <div class="cancel-btn">
                  <el-button @click="cancelRemove(item)">取消</el-button>
                  <el-button type="danger" @click="removeFile(item)"
                    >确定</el-button
                  >
                </div>
              </div>
              <i class="el-icon-delete" slot="reference"></i>
            </el-popover>
          </div>
          <el-checkbox class="checkbox" v-model="item.checked"></el-checkbox>
        </div>
      </div>
      <f-empty v-if="!fileList.length && isLoaded"></f-empty>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
    <c-edit-file-dialog
      :visible.sync="editFileDialogVisible"
      :data="activeFile"
      @success="getFileList"
    ></c-edit-file-dialog>
  </div>
</template>

<script>
import CEditFileDialog from "./_components/editFile";
import { getDocList, deleteDoc } from "@/api/message";
export default {
  components: {
    CEditFileDialog
  },
  data() {
    return {
      fileList: [],
      // 显示上传 编辑 文档对话框
      editFileDialogVisible: false,
      // 当前操作的文档条目
      activeFile: {},
      isLoaded: false
    };
  },
  methods: {
    cancelRemove(item) {
      item.showPopover = false;
    },
    // 获取文档文件列表
    async getFileList() {
      this.isLoaded = false;
      const res = await getDocList();
      this.isLoaded = true;
      if (res) {
        this.fileList = res.map(item => {
          return {
            ...item,
            showPopover: false,
            checked: false
          };
        });
      }
    },
    // 从文档库删除文档
    async removeFile(item) {
      const res = await deleteDoc({ id: item.id });
      if (res) {
        this.getFileList();
      }
    },
    // 点击编辑按钮显示编辑文档
    handleEditClick(file) {
      this.activeFile = file;
      this.editFileDialogVisible = true;
    },
    close() {
      this.$emit("update:visible", false);
    },
    // 确定
    confirm() {
      const result = this.fileList.filter(item => item.checked);
      if (result.length) {
        this.$emit("select", result);
        this.close();
      } else {
        this.$showError("请选择相关文档");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.hand-popover {
  padding: 14px 10px;
  .cancel-btn {
    margin-top: 16px;
    @include flex-ycb;
    .el-button {
      flex: 1;
    }
  }
}
.head {
  @include flex-ycb;
  ::v-deep {
    .el-button--info {
      color: #666;
    }
  }
}
.file-list {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  .item {
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    &:hover {
      background: #ecf5ff;
    }
    .img-box {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      img {
        max-width: 30px;
        height: 30px;
      }
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title {
      font-size: 14px;
      color: #333;
      line-height: 160%;
    }
    p {
      font-size: 12px;
      color: #aaa;
      line-height: 100%;
    }
  }
  .actions {
    font-size: 20px;
    color: #999;
    i {
      padding: 10px;
      cursor: pointer;
    }
  }
  .checkbox {
    margin-left: 50px;
    ::v-deep {
      .el-checkbox__inner {
        width: 20px;
        height: 20px;
        &:after {
          left: 6px;
          top: 2px;
          width: 5px;
          height: 9px;
        }
      }
    }
  }
}
</style>
