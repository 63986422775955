<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="`${formData.id ? '编辑文档' : '本地上传文档'}`"
    width="700px"
  >
    <el-form ref="form" label-position="left" label-width="100px">
      <el-form-item label="选择文档">
        <p class="tips">支持Word、PDF、Excel，单个文档不超过10M</p>
        <f-upload
          size="mini"
          accept=".pdf, .doc, .docx, .xls, .xlsx"
          :type="8"
          :limit-size="10"
          :default-file-list="defaultFile"
          @on-success="fileList => handleUploadSuccess(fileList)"
          @on-remove="handleFileRemove"
        ></f-upload>
      </el-form-item>
      <el-form-item label="文档标题">
        <el-input
          v-model="formData.title"
          placeholder="请输入"
          @input="handleTitleInput"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import { createDoc, editDoc } from "@/api/message";
const formData = {
  docType: "", // 档类型 pdf excel word image
  docName: "", //原档的名字
  id: null, // 文档id
  size: 0, // 文档大小
  suffix: "", // 文档类型后缀
  title: "", // 文档标题
  type: 2, //文档类型 1文档 2图片
  unit: "b", // 大小单位
  url: "" // 文档路径
};
export default {
  components: {
    FUpload
  },
  props: {
    data: Object
  },
  data() {
    return {
      formData: this.$deepClone(formData),
      defaultFile: undefined
    };
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val) {
          this.formData = this.$deepClone(val);
          this.defaultFile = this.$deepClone({
            ...val,
            name: `${val.title}`
          });
        }
      }
    }
  },
  methods: {
    // 上传成功
    handleUploadSuccess([file]) {
      const pointIndex = file.name.lastIndexOf(".");
      this.formData = {
        url: file.url,
        title: file.name.slice(0, pointIndex), // 排除后缀
        docName: file.name,
        size: file.size,
        docType: file.docType,
        suffix: file.name.slice(pointIndex + 1) // 截取后缀
      };
      this.defaultFile = this.$deepClone(file);
    },
    // 移除
    handleFileRemove() {
      this.formData = this.$deepClone(formData);
    },
    // 输入标题
    handleTitleInput() {
      const name = `${this.formData.title}.${this.formData.suffix}`;
      this.defaultFile.name = name;
      this.formData.docName = name;
    },
    close() {
      this.formData = this.$deepClone(formData);
      this.defaultFile = null;
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    // 提交
    async submit() {
      const fn = this.formData.id ? editDoc : createDoc;
      const res = await fn({ massDocList: [this.formData] });
      if (res) {
        this.close();
        this.$emit("success");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 40px;
  color: #999;
}
::v-deep {
  .el-upload {
    .file {
      .text {
        .name {
          max-width: 350px;
        }
      }
    }
  }
}
</style>
