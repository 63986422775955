<template>
  <div class="f-editor" id="f-editor">
    <quill-editor
      class="editor"
      v-model="content"
      ref="QuillEditor"
      :options="editorOption"
      @change="onEditorChange($event)"
    >
    </quill-editor>
    <f-upload
      type="2"
      ref="picUploader"
      class="pic-uploader"
      @on-success="imgUploadSuccess"
    ></f-upload>
  </div>
</template>
<script>
import FUpload from "../upload";
import OSS from "ali-oss";
import { Quill, quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 表情插组件
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

const fontSizeStyle = Quill.import("attributors/style/size");
// 图片缩放组件
// import ImageResize from "quill-image-resize-module";

// // 图片拖动组件
// import { ImageDrop } from "quill-image-drop-module";

Quill.register("modules/quillEmoji", quillEmoji);
// Quill.register("modules/imageDrop", ImageDrop);
// Quill.register("modules/imageResize", ImageResize);

Quill.register(fontSizeStyle, true);

import {
  imageUrlToBase64,
  dataURLtoBlob,
  generateRandomNum
} from "@/utils/index";
import { getOSSParam } from "@/api/common";
import rules from "@/utils/rules";
fontSizeStyle.whitelist = ["12px", "14px", false, "20px", "24px", "36px"];

// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{'direction': 'rtl'}],                         // 文本方向
  // [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [
    {
      size: fontSizeStyle.whitelist
    }
  ], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  ["clean"], // 清除文本格式
  ["link", "image"], // 链接、图片、视频
  ["emoji"] //表情包
];

export default {
  props: {
    defaultContent: String
  },
  components: {
    quillEditor,
    FUpload
  },
  data() {
    return {
      ossParam: {},
      content: ``,
      pastedImgSrcs: [] //粘贴的图片途径
    };
  },
  computed: {
    editorOption() {
      //编辑器初始化参数
      const _this = this;
      return {
        theme: "snow", // or 'bubble'
        placeholder: "请输入",
        modules: {
          "emoji-toolbar": true,
          "emoji-shortname": true,
          // imageDrop: true, //图片拖拽
          // imageResize: {
          //   //放大缩小
          //   displayStyles: {
          //     backgroundColor: "black",
          //     border: "none",
          //     color: "white"
          //   },
          //   modules: ["Resize", "DisplaySize", "Toolbar"]
          // },
          toolbar: {
            container: toolbarOptions,
            // container: "#toolbar",
            handlers: {
              image(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".pic-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              link(value) {
                const quill = _this.$refs.QuillEditor.quill;
                const index = quill.selection.savedRange.index;
                //显示链接输入框之前保存光标位置
                if (value) {
                  _this
                    .$prompt("请输入网络图片链接", "提示", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      inputPattern: rules.url.pattern,
                      inputErrorMessage: "链接格式有误"
                    })
                    .then(async res => {
                      quill.setSelection(index); //设置光标到之前位置
                      _this.uploadNetworkImg(res.value, imgURL => {
                        _this.imgUploadSuccess([{ url: imgURL }]);
                      });
                    })
                    .catch(() => {
                      quill.setSelection(index); //设置光标到之前位置
                    });
                } else {
                  _this.quill.format("link", false);
                }
              }
            }
          }
        }
      };
    }
  },
  watch: {
    defaultContent(val) {
      this.content = this.escapeStringHTML(val);
    }
  },
  async created() {
    this.registerFileBlot();
    this.registerLinkBlot();
    this.ossParam = await this.getOSSParam();
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    this.elEditor.removeListener("click", this.eventBind);
  },
  methods: {
    addListener() {
      this.elEditor = document.querySelector("#f-editor");
      this.elEditor.addEventListener("click", this.eventBind);
    },
    // 点击超链接的时候打开新窗口
    eventBind(e) {
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href);
      }
    },
    async getOSSParam() {
      if (!this.ossParam.expiration) {
        return await getOSSParam();
      }
      // 参数过期时间
      const remainingTime =
        (new Date(this.ossParam.expiration) - Date.now()) / 1000 / 60;
      // 如果未过期
      if (remainingTime > 1) {
        return this.ossParam;
      } else {
        //过期则重新请求
        return await getOSSParam();
      }
    },
    clear() {
      this.content = "";
    },
    async uploadImg(file) {
      //上传图片
      this.ossParam = await this.getOSSParam();
      let suffix = file.type.split("/")[1];
      console.log(file, suffix);
      let fileName = `fangyibao${Date.now()}-${generateRandomNum()}`;
      let client = new OSS({ ...this.ossParam, secure: true }); //初始化oss
      let resURL = "";
      suffix = suffix == "jpeg" ? "jpg" : suffix;
      fileName = `${this.ossParam.file}/${fileName}.${suffix}`;
      await client
        .multipartUpload(fileName, file)
        .then(res => {
          let url = res.res.requestUrls[0];
          resURL = url.split("?")[0];
        })
        .catch(async () => {
          this.$showError("上传失败");
          this.ossParam = await this.getOSSParam();
        });
      return resURL;
    },
    imgUploadSuccess(list) {
      // 图片上传成功
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (list && list[0]) {
        // 获取光标所在位置
        let index = quill.selection.savedRange.index;
        let src = list[0].url;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(index, "image", src);
        // 调整光标到最后
        quill.setSelection(index + 1);
      }
    },
    async uploadAllImg() {
      //上传所有图片
      let res = this.content;
      let hasFail = false;
      let imgSrcs = res.match(/<img.*?src="([^"]+)/gi) || [];
      let count = 0;
      const placeholderImg =
        "https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/file/2020/09/04/fangyibao1599215036879-78479.jpg";
      imgSrcs = imgSrcs.map(item => {
        return item.split('"')[1];
      });
      //如果包含失败占位图
      if (imgSrcs.includes(placeholderImg)) {
        return { content: res, hasFail: true };
      }
      imgSrcs = imgSrcs.filter(item => {
        return (
          !item.includes("fangyibao-res") &&
          !item.includes("cdnoss.fangyibao.cn")
        );
      });
      for (let src of imgSrcs) {
        let src2 = src.replace("&amp;", "&");
        await this.uploadNetworkImg(
          src2,
          imgURL => {
            res = res.replace(src, imgURL);
          },
          () => {
            hasFail = true;
            res = res.replace(src, placeholderImg); //图片下载失败 替换为占位图
          }
        );
        count++;
      }
      if (count === imgSrcs.length) {
        this.content = res;
        return { content: res, hasFail };
      }
    },
    onEditorChange(data) {
      // 内容改变事件
      this.$emit("on-change", data);
    },
    async uploadNetworkImg(url, success, fail) {
      //上传网络图片到我们的服务器
      const dataURL = await imageUrlToBase64(url); //图片转换成base64
      if (dataURL) {
        const blob = dataURLtoBlob(dataURL); //base64转blob
        const imgURL = await this.uploadImg(blob); //上传到我们服务器
        success && success(imgURL);
      } else {
        fail && fail();
      }
    },
    escapeStringHTML(str) {
      // 转码
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    // 注册自定义自定义文件blot
    registerBlot() {
      const BlockEmbed = Quill.import("blots/block/embed");
      class Blot extends BlockEmbed {
        static create(value) {
          const node = super.create(value);
          node.setAttribute("contenteditable", false);
          node.setAttribute("width", "100%");
          //   设置自定义html
          node.innerHTML = value;
          return node;
        }
        // 去除字符串模板(``)语法中存在的空格
        static transformValue(value) {
          let handleArr = value.split("\n");
          handleArr = handleArr.map(e =>
            e.replace(/^[\s]+/, "").replace(/[\s]+$/, "")
          );
          return handleArr.join("");
        }

        // 返回节点自身的value值 用于撤销操作
        static value(node) {
          return node.innerHTML;
        }
      }
      return Blot;
    },
    // 自定义文件blot
    registerFileBlot() {
      // 定义新的blot类型
      const FileEmbed = this.registerBlot();
      FileEmbed.blotName = "FileEmbed";
      FileEmbed.className = "embed-custom-file";
      FileEmbed.tagName = "div";
      Quill.register(FileEmbed, true);
    },
    // 自定义链接blot
    registerLinkBlot() {
      // 定义新的blot类型
      const LinkEmbed = this.registerBlot();
      LinkEmbed.blotName = "LinkEmbed";
      LinkEmbed.className = "embed-custom-link";
      LinkEmbed.tagName = "div";
      Quill.register(LinkEmbed, true);
    },

    //插入链接模块
    insertLinkBlot(data) {
      const quill = this.$refs.QuillEditor.quill;
      // 获取光标所在位置
      const index = quill.selection.savedRange.index;
      if (data) {
        const html = `<a class="embed-custom-link-inner" data-islink="true" href="${data.link}">
          ${data.title}
        </a>`;
        quill.insertEmbed(index, "LinkEmbed", html);
        quill.setSelection(index + 1);
      }
    },
    //插入附件模块
    insertFileBlot(data) {
      const quill = this.$refs.QuillEditor.quill;
      // 获取光标所在位置
      if (Array.isArray(data) ? data.length : data) {
        const inset = data => {
          const html = `<div class="embed-custom-file-inner" contenteditable="false" data-isfile="true" data-url="${
            data.url
          }" data-doctype="${data.docType}" data-title=${data.title}>
          <img class="icon" src="https://cdnoss.fangyibao.cn/image/doc-fill.png" />
          <div class="text">
            <p class="name">${data.title}.${data.suffix}</p>
            <span class="desc">${this.$format.byte(data.size)}</span>
          </div>  
        </div>`;
          const index = quill.selection.savedRange.index;
          quill.insertEmbed(index, "FileEmbed", html);
          quill.setSelection(index + 1);
        };
        if (Array.isArray(data)) {
          data.forEach(item => inset(item));
        } else {
          inset(data);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.pic-uploader {
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
}
.f-editor {
  .editor {
    line-height: normal !important;
    height: 800px;
    .ql-editor {
      &:before {
        font-style: normal;
        font-size: 14px;
        color: #aaa;
      }
      // 如果图片是 上传失败 后的占位符 就让他宽度固定，方便快速定位
      img[src="https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/file/2020/09/04/fangyibao1599215036879-78479.jpg"] {
        width: 417px !important;
        height: 337px !important;
      }
    }
  }
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="38px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="38px"]::before {
  content: "38px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="45px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="45px"]::before {
  content: "45px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="50px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="50px"]::before {
  content: "50px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

.ql-snow .ql-editor img {
  display: block;
  max-width: 60%;
  margin: 20px auto;
}

.embed-custom-link {
  margin: 20px 0;
  a {
    &.embed-custom-link-inner {
      text-decoration: none;
      white-space: normal;
      color: #2369a2;
    }
  }
}

.embed-custom-file {
  width: fit-content;
  margin: 20px auto;
  width: 60%;
  &-inner {
    padding: 10px;
    background: #f1f1f1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    &:hover {
      background: #eee;
    }
    .icon {
      width: 30px;
      height: 30px;
      margin: 0 !important;
    }
    .text {
      padding-left: 10px;
      text-align: left;
      width: calc(100% - 40px);
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .name {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }
      .desc {
        padding-top: 4px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}

.ap {
  background-image: none;
  margin-top: 0;
  text-indent: 0;
  width: 25px;
}
.ql-editor {
  font-size: 16px;
  line-height: 1.8;
  &::before {
    font-size: 16px;
  }
  p {
    line-height: 1.8;
  }
  .ap {
    width: auto;
    height: auto;
    font-size: inherit;
  }
}
</style>
