var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "title": "".concat(_vm.formData.id ? '编辑文档' : '本地上传文档'),
      "width": "700px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "label-position": "left",
      "label-width": "100px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "选择文档"
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("支持Word、PDF、Excel，单个文档不超过10M")]), _c('f-upload', {
    attrs: {
      "size": "mini",
      "accept": ".pdf, .doc, .docx, .xls, .xlsx",
      "type": 8,
      "limit-size": 10,
      "default-file-list": _vm.defaultFile
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.handleUploadSuccess(fileList);
      },
      "on-remove": _vm.handleFileRemove
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "文档标题"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    on: {
      "input": _vm.handleTitleInput
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }