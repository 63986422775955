var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "close-on-click-modal": false,
      "width": "700px",
      "title": "选择文档"
    },
    on: {
      "open": _vm.getFileList
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "head"
  }, [_c('p', [_vm._v("文档库")]), _c('el-button', {
    attrs: {
      "type": "info",
      "size": "small"
    },
    on: {
      "click": function click($event) {
        return _vm.handleEditClick(null);
      }
    }
  }, [_vm._v("本地上传")])], 1), _c('div', {
    staticClass: "file-list"
  }, _vm._l(_vm.fileList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "img-box"
    }, [item.docType == 'word' ? _c('img', {
      attrs: {
        "src": require("@/assets/word.png")
      }
    }) : _vm._e(), item.docType == 'excel' ? _c('img', {
      attrs: {
        "src": require("@/assets/excel.png")
      }
    }) : _vm._e(), item.docType == 'pdf' ? _c('img', {
      attrs: {
        "src": require("@/assets/pdf.png")
      }
    }) : _vm._e(), item.docType == 'image' ? _c('img', {
      attrs: {
        "src": item.url
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.docName))]), _c('p', [_vm._v(" 大小 " + _vm._s(_vm.$format.byte(item.size)) + " "), _c('span', [_vm._v(" " + _vm._s(item.createTime))])])]), _c('div', {
      staticClass: "actions"
    }, [_c('i', {
      staticClass: "el-icon-edit-outline",
      on: {
        "click": function click($event) {
          return _vm.handleEditClick(item);
        }
      }
    }), _c('el-popover', {
      attrs: {
        "placement": "bottom-end",
        "width": "290",
        "trigger": "click"
      },
      model: {
        value: item.showPopover,
        callback: function callback($$v) {
          _vm.$set(item, "showPopover", $$v);
        },
        expression: "item.showPopover"
      }
    }, [_c('div', {
      staticClass: "hand-popover"
    }, [_vm._v(" 删除该文档后将无法恢复，所有引用该文档的网页中对应的文档都会被删除。 "), _c('div', {
      staticClass: "cancel-btn"
    }, [_c('el-button', {
      on: {
        "click": function click($event) {
          return _vm.cancelRemove(item);
        }
      }
    }, [_vm._v("取消")]), _c('el-button', {
      attrs: {
        "type": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(item);
        }
      }
    }, [_vm._v("确定")])], 1)]), _c('i', {
      staticClass: "el-icon-delete",
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    })])], 1), _c('el-checkbox', {
      staticClass: "checkbox",
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    })], 1);
  }), 0), !_vm.fileList.length && _vm.isLoaded ? _c('f-empty') : _vm._e(), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("确定")])], 1)], 1), _c('c-edit-file-dialog', {
    attrs: {
      "visible": _vm.editFileDialogVisible,
      "data": _vm.activeFile
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editFileDialogVisible = $event;
      },
      "success": _vm.getFileList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }